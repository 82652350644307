import React from 'react'
import Mainsection from '../Components/MainSection';

const Main = () => {
  
  return (
    <>
     <Mainsection /> 
    </>
  )
}
export default Main
