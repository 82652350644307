import React from 'react'
import {
  MainContainer,
  MainContent,
  MainH1,
  MainP1
} from './mElements';

const Mainsection = () => {

  return (
    <MainContainer id="home" >
      <MainContent>
        <MainH1> Bilal Baig </MainH1>
        <MainP1> Software Engineer </MainP1>
      </MainContent>
    </MainContainer>
  )
}

export default Mainsection
