import React from 'react'
import Cards from '../Components/ExperienceCards'


const About = () => {
  
  return (
    <div >
     <Cards />
     </div>
  )
}
export default About
