import React from 'react'
import Infosection from '../Components/infosec';
import {  homeObjOne } from '../Components/infosec/Data';

const About = () => {
  
  return (
    <>
    <Infosection {...homeObjOne}/>
    </>
  )
}
export default About
